import { ApiUrl, getMetadata } from '../index'
import { Property, PropertyLocation } from './property_pb'
import {
  GetPropertyRequest,
  ListPropertiesRequest,
  ApplyPropertyRequest,
  GeneratePropertyDescriptionRequest,
  DeletePropertyRequest
} from './properties_pb'
import { PropertiesClient } from './properties_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'
import { Money, MoneyRange } from '../money_pb'
import { Unit, UnitRange } from '../unit_pb'
import { ListOfStrings } from '../list_of_strings_pb'

let client = new PropertiesClient(ApiUrl, null, null);

const toPropertyModel = (instance) => {
  let result = new Property()
  if (instance.name) {
    result.setName(instance.name)
  }
  if (instance.displayName) {
    result.setDisplayName(instance.displayName)
  }
  if (instance.developerName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.developerName)
    result.setDeveloperName(wrapped)
  }
  if (instance.projectName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.projectName)
    result.setProjectName(wrapped)
  }
  if (instance.projectDisplayName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.projectDisplayName)
    result.setProjectDisplayName(wrapped)
  }
  if (instance.description) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.description)
    result.setDescription(wrapped)
  }
  if (instance.publicDescription) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.publicDescription)
    result.setPublicDescription(wrapped)
  }
  if (instance.externalNumber) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.externalNumber)
    result.setExternalNumber(wrapped)
  }
  if (instance.externalSystem) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.externalSystem)
    result.setExternalSystem(wrapped)
  }
  if (instance.externalIncluded) {
    result.setExternalIncluded(true)
  }
  if (instance.externalUrl) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.externalUrl)
    result.setExternalUrl(wrapped)
  }
  if (instance.paymentPlanDescription) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.paymentPlanDescription)
    result.setPaymentPlanDescription(wrapped)
  }
  if (instance.note) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.note)
    result.setNote(wrapped)
  }
  result.setIsProject(!!instance.isProject === true)
  result.setIsFeatured(!!instance.isFeatured === true)
  result.setIsVerified(!!instance.isVerified === true)
  result.setIsUpdated(!!instance.isUpdated === true)
  result.setHasCoownership(!!instance.hasCoownership === true)
  result.setReservedShared(instance.reservedShared)
  if (instance.typeName) {
    result.setTypeName(instance.typeName)
  }
  if (!!instance.forSale === true) {
    result.setForSale(instance.forSale)
  }
  if (!!instance.forRent === true) {
    result.setForRent(instance.forRent)
  }
  if (instance.supportedTypeNamesList) {
    result.setSupportedTypeNamesList(instance.supportedTypeNamesList)
  }
  if (instance.statusName) {
    result.setStatusName(instance.statusName)
  }
  if (instance.amount?.value) {
    const amt = new Money()
    amt.setValue(instance.amount.value)
    amt.setCurrencyName(instance.amount.currencyName)
    amt.setExponent(instance.amount.exponent)
    result.setAmount(amt)
  }
  if (instance.dailyRent?.value) {
    const amt = new Money()
    amt.setValue(instance.dailyRent.value)
    amt.setCurrencyName(instance.dailyRent.currencyName)
    amt.setExponent(instance.dailyRent.exponent)
    result.setDailyRent(amt)
  }
  if (instance.monthlyRent?.value) {
    const amt = new Money()
    amt.setValue(instance.monthlyRent.value)
    amt.setCurrencyName(instance.monthlyRent.currencyName)
    amt.setExponent(instance.monthlyRent.exponent)
    result.setMonthlyRent(amt)
  }
  if (instance.estimatedMonthlyRent?.value) {
    const amt = new Money()
    amt.setValue(instance.estimatedMonthlyRent.value)
    amt.setCurrencyName(instance.estimatedMonthlyRent.currencyName)
    amt.setExponent(instance.estimatedMonthlyRent.exponent)
    result.setEstimatedMonthlyRent(amt)
  }
  if (instance.hoaMonthlyFee?.value) {
    const amt = new Money()
    amt.setValue(instance.hoaMonthlyFee.value)
    amt.setCurrencyName(instance.hoaMonthlyFee.currencyName)
    amt.setExponent(instance.hoaMonthlyFee.exponent)
    result.setHoaMonthlyFee(amt)
  }
  if (instance.upgradeCost?.value) {
    const amt = new Money()
    amt.setValue(instance.upgradeCost.value)
    amt.setCurrencyName(instance.upgradeCost.currencyName)
    amt.setExponent(instance.upgradeCost.exponent)
    result.setUpgradeCost(amt)
  }

  result.setVat(!!instance.vat === true)
  if (instance.location) {
    const loc = new PropertyLocation()

    if (instance.location.countryName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(instance.location.countryName)
      loc.setCountryName(wrapped)
    }
    if (instance.location.stateName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(instance.location.stateName)
      loc.setStateName(wrapped)
    }
    if (instance.location.cityName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(instance.location.cityName)
      loc.setCityName(wrapped)
    }
    if (instance.location.zip) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(instance.location.zip)
      loc.setZip(wrapped)
    }
    if (instance.location.addressLine1) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(instance.location.addressLine1)
      loc.setAddressLine1(wrapped)
    }
    if (instance.location.addressLine2) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(instance.location.addressLine2)
      loc.setAddressLine2(wrapped)
    }
    if (instance.location.appartment) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(instance.location.appartment)
      loc.setAppartment(wrapped)
    }
    if (instance.location.districtName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(instance.location.districtName)
      loc.setDistrictName(wrapped)
    }
    if (instance.location.settlementName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(instance.location.settlementName)
      loc.setSettlementName(wrapped)
    }
    if (instance.location.quarterName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(instance.location.quarterName)
      loc.setQuarterName(wrapped)
    }
    if (instance.location.latitude) {
      const wrapped = new wrappers.DoubleValue()
      wrapped.setValue(instance.location.latitude)
      loc.setLatitude(wrapped)
    }
    if (instance.location.longitude) {
      const wrapped = new wrappers.DoubleValue()
      wrapped.setValue(instance.location.longitude)
      loc.setLongitude(wrapped)
    }

    result.setLocation(loc)
  }
  for (const i in instance.valuesMap) {
    result.getValuesMap().set(instance.valuesMap[i][0], instance.valuesMap[i][1])
  }
  for (const i in instance.amenitiesMap) {
    result.getAmenitiesMap().set(instance.amenitiesMap[i][0], instance.amenitiesMap[i][1])
  }
  for (const i in instance.unitsMap) {
    const unt = new Unit()
    const val = instance.unitsMap[i][1]
    unt.setValue(val.value)
    unt.setUnitName(val.unitName)
    unt.setExponent(val.exponent)
    result.getUnitsMap().set(instance.unitsMap[i][0], unt)
  }
  for (const i in instance.photosMap) {
    const items = new ListOfStrings()
    items.setStringsList(instance.photosMap[i][1].stringsList)
    result.getPhotosMap().set(instance.photosMap[i][0], items)
  }
  return result
}

const toPropertyObject = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || 
      ["amount", "dailyRent", "monthlyRent", "estimatedMonthlyRent", "hoaMonthlyFee", "upgradeCost"].includes(key)) {
      result[key] = instance[key]
    } else if (key === "project" && instance[key]) {
      result[key] = toPropertyObject(instance[key])
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else if (key === 'location') {
      result[key] = toPropertyObject(instance[key])
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listProperties: (params) => {
    const request = new ListPropertiesRequest();
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort === 'asc')
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.propertyCollectionName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.propertyCollectionName)
      request.setPropertyCollectionName(wrapped)
    }
    if (params?.propertyQueryName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.propertyQueryName)
      request.setPropertyQueryName(wrapped)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.forSaleOnly) {
      request.setForSaleOnly(params?.forSaleOnly)
    }
    if (params?.forRentOnly) {
      request.setForRentOnly(params?.forRentOnly)
    }
    if (params?.typeName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.typeName)
      request.setTypeName(wrapped)
    }
    if (params?.statusName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.statusName)
      request.setStatusName(wrapped)
    }
    if (params?.developerName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.developerName)
      request.setDeveloperName(wrapped)
    }
    if (params?.projectName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.projectName)
      request.setProjectName(wrapped)
    }
    if (params?.isProject !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params.isProject)
      request.setIsProject(wrapped)
    }
    if (params?.externalIncluded) {
      request.setExternalIncluded(params.externalIncluded)
    }
    if (params?.externalSystem) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.externalSystem)
      request.setExternalSystem(wrapped)
    }
    if (params?.externalNumber) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.externalNumber)
      request.setExternalNumber(wrapped)
    }
    if (params?.isVerified !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params.isVerified)
      request.setIsVerified(wrapped)
    }
    if (params?.isUpdated !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params.isUpdated)
      request.setIsUpdated(wrapped)
    }
    if (params?.developerNamesList?.length) {
      request.setDeveloperNamesList(params?.developerNamesList)
    }
    if (params?.typeNamesList?.length) {
      request.setTypeNamesList(params?.typeNamesList)
    }
    if (params?.statusNamesList) {
      request.setStatusNamesList(params?.statusNamesList)
    }
    if (params?.locationNamesList) {
      request.setLocationNamesList(params?.locationNamesList)
    }
    if (params?.amountRange) {
      const range = new MoneyRange()
      if (params?.amountRange?.from) {
        const amt = new Money()
        amt.setValue(params?.amountRange?.from.value)
        amt.setCurrencyName(params?.amountRange?.from.currencyName)
        amt.setExponent(params?.amountRange?.from.exponent)
        range.setFrom(amt)
      }
      if (params?.amountRange?.to) {
        const amt = new Money()
        amt.setValue(params?.amountRange?.to.value)
        amt.setCurrencyName(params?.amountRange?.to.currencyName)
        amt.setExponent(params?.amountRange?.to.exponent)
        range.setTo(amt)
      }
      request.setAmountRange(range)
    }
    if (params?.rentRange) {
      const range = new MoneyRange()
      if (params?.rentRange?.from) {
        const amt = new Money()
        amt.setValue(params?.rentRange?.from.value)
        amt.setCurrencyName(params?.rentRange?.from.currencyName)
        amt.setExponent(params?.rentRange?.from.exponent)
        range.setFrom(amt)
      }
      if (params?.rentRange?.to) {
        const amt = new Money()
        amt.setValue(params?.rentRange?.to.value)
        amt.setCurrencyName(params?.rentRange?.to.currencyName)
        amt.setExponent(params?.rentRange?.to.exponent)
        range.setTo(amt)
      }
      request.setRentRange(range)
    }
    if (params?.valuesMap) {
      for (const i in params.valuesMap) {
        if (params.valuesMap[i][1] === undefined)
          continue
        const items = new ListOfStrings()
        items.setStringsList(params.valuesMap[i][1])
        request.getValuesMap().set(params.valuesMap[i][0], items)
      }
    }
    if (params?.amenitiesMap) {
      for (const i in params.amenitiesMap) {
        if (params.amenitiesMap[i][1] === undefined)
          continue
        const items = new ListOfStrings()
        items.setStringsList(params.amenitiesMap[i][1])
        request.getAmenitiesMap().set(params.amenitiesMap[i][0], items)
      }
    }
    if (params?.unitRangesMap) {
      for (const i in params.unitRangesMap) {
        if (params.unitRangesMap[i][1] === undefined)
          continue
        const range = new UnitRange()
        const item = params.unitRangesMap[i][1]
        if (item?.from) {
          const unt = new Unit()
          unt.setValue(item?.from.value)
          unt.setUnitName(item?.from.unitName)
          unt.setExponent(item?.from.exponent)
          range.setFrom(unt)
        }
        if (item?.to) {
          const unt = new Unit()
          unt.setValue(item?.to.value)
          unt.setUnitName(item?.to.unitName)
          unt.setExponent(item?.to.exponent)
          range.setTo(unt)
        }
        request.getUnitRangesMap().set(params.unitRangesMap[i][0], range)
      }
    }

    return new Promise((resolve, reject) => {
      client.listProperties(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            propertiesList: result.propertiesList.map(p => toPropertyObject(p))
          })
        }
      })
    })
  },
  getProperty: (name) => {
    const request = new GetPropertyRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.getProperty(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(toPropertyObject(response.toObject()))
        }
      });
    });
  },
  applyProperty: (instance) => {
    const applying = toPropertyModel(instance)
    const request = new ApplyPropertyRequest()
    request.setProperty(applying)
    return new Promise((resolve) => {
      client.applyProperty(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(toPropertyObject(response.toObject()))
        }
      })
    })
  },
  deleteProperty: (name) => {
    const request = new DeletePropertyRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.deleteProperty(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  generatePropertyDescription: (name) => {
    const request = new GeneratePropertyDescriptionRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.generatePropertyDescription(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  }
};

export default API;
